import { app } from "./app";
import axios from "axios";
import {
  getAuth,
  onAuthStateChanged,
  // signInWithEmailAndPassword,
  signOut,
  updateProfile,
  User,
} from "firebase/auth";
import { UserInfo } from "@/@types/user";

const auth = getAuth(app);

interface UserDataCommon {
  user: UserInfo | null;
  error: boolean;
}

export interface CreateDropChatPayload {
  title: string;
  location: [number, number];
  reach_area_radius: number;
}

export interface UserDataResponse extends UserDataCommon {
  user: UserInfo;
  error: false;
  errorData: null;
}
export interface UserDataError extends UserDataCommon {
  user: null;
  error: true;
  errorData: {
    code: string;
    info: string;
  };
}

export default class Users {
  static signIn(email: string, password: string) {
    return axios
      .post("https://dev.dropchats.one/api/token", {
        username: email, // Use username as per DropChats API
        password: password,
      })
      .then((data: any) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
        const err = {
          code: error.code,
          message: error.response.data.message,
        };
        return userDataReject(err);
      });

    //const { registration_status, token ,access} = ; // Assuming the response includes a token and user info
  }

  static async getDropChat(token: string) {
    return axios
      .get("https://dev.dropchats.one/api/rooms?chat_types=dropchat", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: any) => {
        console.log("rooms", response.data);
        return response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
        return error;
      });
  }

  static async createDropChat(token: string, data: CreateDropChatPayload) {
    return axios
      .post("https://dev.dropchats.one/api/rooms", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: any) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
        return error;
      });
  }

  static async listDropChats(token: string, data: object) {
    return axios
      .post("https://dev.dropchats.one/api/dropchat_list", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: any) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
        return error;
      });
  }

  static async getDropChatList(token: string) {
    return axios
      .get("https://dev.dropchats.one/api/dropchat_list", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: any) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error:", error);
        return error;
      });
  }

  static signOut(): Promise<boolean> {
    return signOut(auth)
      .then(() => {
        return true;
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
  }

  static getCurrentUser() {
    return new Promise<UserDataResponse | UserDataError>((resolve, reject) => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          resolve(toUserDataResponse(user));
        } else reject(userDataReject(null));
      });
    });
  }

  static updateProfileData(data: {
    displayName?: string | null | undefined;
    photoURL?: string | null | undefined;
  }): Promise<boolean> {
    if (!auth.currentUser) return Promise.resolve(false);
    return updateProfile(auth.currentUser, data)
      .then(() => true)
      .catch(() => false);
  }

  // static async getUserList() {
  //   // const usersRef = collection(db, "users");
  //   // const q = query(usersRef);
  //   // const querySnapshot = await (
  //   //   await getDocs(q)
  //   // ).docs.map((doc) => doc.data() as UserInfo);
  //   // return querySnapshot;

  //   return axios
  //     .get("https://dev.dropchats.one/api/users")
  //     .then((data: any) => {
  //       console.log("------------");
  //       console.log(data);
  //       return data;
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       const err = {
  //         code: error.code,
  //         message: error.response.data.message,
  //       };
  //       return err;
  //     });
  // }
}

function toUserDataResponse(user: User): UserDataResponse {
  return {
    user: {
      uid: user.uid,
      name: user.displayName || "NoName",
      email: user.email || "",
      avatarURL: user.photoURL || "",
    },
    error: false,
    errorData: null,
  };
}

// function toUserDocDataResponse(user: UserInfo): UserDataResponse {
//   return {
//     user: {
//       uid: user.uid,
//       name: user.name || "NoName",
//       email: user.email || "",
//       avatarURL: user.avatarURL || "",
//     },
//     error: false,
//     errorData: null,
//   };
// }

function userDataReject(error: unknown): UserDataError {
  const errorData = getError(error);
  return {
    user: null,
    error: true,
    errorData,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getError(error: any) {
  if (typeof error === "object" && error && error.code && error.message)
    return {
      code: error.code as string,
      info: error.message as string,
    };
  else
    return {
      code: "undefined",
      info: "no info",
    };
}
