import { UserInfo } from "@/@types/user";
// import Chat from "@/api/chat";
import Users from "@/api/users";
import router from "@/router";
import { Unsubscribe } from "firebase/firestore";

interface SignInForm {
  email: string;
  password: string;
}

import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
// import { dialogsStore } from "./dialogs";

@Module({
  name: "user",
})
export default class User extends VuexModule {
  user: UserInfo = {
    uid: "",
    name: "",
    email: "",
    avatarURL: "",
  };
  isAuth = false;
  token = "";

  private unsub: Unsubscribe | null = null;

  @Action
  async logIn({ email, password }: SignInForm) {
    const response = await Users.signIn(email, password);
    console.log(response);
    if (response.error) return response;

    if (response.data.token) {
      localStorage.setItem("access_token", response.data.token);
      this.updateAuth(true);
      this.updateToken(response.data.token);
      const user: UserInfo = {
        uid: "",
        name: email,
        email: "",
        avatarURL: "",
      };
      this.updateUser(user);
      router.push({ name: "home" });
    }

    return response;
  }

  @Action
  async signOut() {
    localStorage.removeItem("access_token");
    router.push({ name: "login" });
    const isLoggedOut = await Users.signOut();
    if (isLoggedOut) {
      if (this.unsub) this.unsub();
      const user: UserInfo = {
        uid: "",
        name: "",
        email: "",
        avatarURL: "",
      };
      this.updateUser(user);
      this.updateAuth(false);
      router.push({ name: "login" });
    }
  }

  @Mutation
  updateUser(user: UserInfo) {
    this.user = user;
  }

  @Mutation
  updateToken(token: string) {
    this.token = token;
  }

  @Mutation
  updateAuth(state: boolean) {
    // if (state && !this.isAuth) {
    //   this.unsub = Chat.subscribeChatsBy(this.user.uid);
    //   dialogsStore.clearDialogs();
    // }
    this.isAuth = state;
  }
}
